import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box, Link, Typography } from "@mui/material";

const App = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={logo} alt="logo" />
      <Typography
        variant="h1"
        component="h1"
        sx={{
          fontFamily: "monospace",
          fontWeight: 500,
          letterSpacing: ".3rem",
          color: "#243D4D",
          textDecoration: "none",
          fontSize: "3rem",
        }}
      >
        MyCashDash
      </Typography>
      <Link href="https://app.mycashdash.com/signin">Sign in </Link>
    </Box>
  );
};

export default App;
